.splash-screen {
  background-color: #00274d; // Azul oscuro del fondo
  position: relative;

  img[alt="Background"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  .relative {
    z-index: 1;
  }

  .loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .school-icon {
    height: 160px;
  }
}

@media (min-width: 1280px) {
  .splash-screen {
    .desktop-bg {
      display: block;
    }
    .mobile-bg {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .splash-screen {
    .desktop-bg {
      display: none;
    }
    .mobile-bg {
      display: block;
    }
  }
}
