.assessment-onbording {
  
  height: calc(100vh - 90px);

  .title {
    color: #3a444a;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  .description {
    margin-top: 10px;
    color: #3a444a;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .content {
    margin-top: 10px;

    .title {
      color: #3a444a;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    .description {
      margin-top: 10px;
      color: #3a444a;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
  .button {
    margin-top: 30px;
    width: 100%;
    display: flex;
    padding: 12px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: #57b4eb;

    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}

.assessment-onbording-desktop {
  background-color: #001d35;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .bg-image {
    position: absolute;
    width: 100vw;
  }

  .logos {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: row;
    gap: 32px;
    top: 32px;
  }

  .header {
    display: flex;
    flex-direction: column;

    .title {
      color: #3a444a;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    .description {
      color: #3a444a;

      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }

  .box {
    position: absolute;
    z-index: 10;
    width: 1052px;
    height: 652px;
    padding: 32px;
    border-radius: 24px;
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.04),
      0px 4px 10px 0px rgba(0, 0, 0, 0.08);

    &-content {
      flex: 1 0 0;
      display: flex;
      flex-direction: row;
      height: calc(100% - 32px);

      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        height: 100%;

        .title {
          color: #3a444a;
          font-family: Montserrat;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .description {
          color: #3a444a;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;
        }

        .content {
          margin-top: 24px;

          .title {
            color: #3a444a;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
          }
          .description {
            color: #3a444a;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;
          }
        }
      }

      .right {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;

        .button {
          width: 98px;
          display: flex;
          height: 40px;
          padding: 7px 8px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: #57b4eb;

          color: #fff;
          text-align: center;

          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.25px;
        }
      }
    }
  }
}
