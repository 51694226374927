.questionnaire {
  width: 100%;
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  .question-text {
    color: #3a444a;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  .question-subtext {
    min-height: 100px;
    color: #3a444a;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .question-step {
    color: #75777a;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;

    .current {
      color: #393c3e;
      font-family: Montserrat;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }
  }

  .selected-option {
    background: #f6faff !important;
    border: 1px solid #85cfff;
  }

  .option {
    padding: 1rem;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);

    color: #5f5e62;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1px;

    .form-radio {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 24px;
      height: 24px;
      border: 0.8px solid #e0e0e0;
      background-color: #fff;
      border-radius: 16px;
      position: relative;
      outline: none;
      cursor: pointer;
    }

    .form-radio:checked::before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #57b4eb;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .form-radio:checked {
      border: 0.8px solid #57b4eb;
    }
  }

  .button {
    display: flex;
    padding: 12px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: #57b4eb;

    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;

    &:disabled {
      background-color: #57dbc8;
    }
  }
}

.questionnaire-desktop {
  background-color: #001d35;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .bg-image {
    position: absolute;
    width: 100vw;
  }

  .logos {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: row;
    gap: 32px;
    top: 24px;
    align-items: center;

    img {
      height: 100px;
    }
  }

  .header {
    display: flex;
    flex-direction: column;

    .title {
      color: #3a444a;
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .description {
      color: #3a444a;

      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }

  .box {
    position: absolute;
    z-index: 10;
    width: 1052px;
    height: 560px;
    top: 124px;
    padding: 32px;
    border-radius: 24px;
    background: #fff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.04),
      0px 4px 10px 0px rgba(0, 0, 0, 0.08);

    &-header {
      display: flex;
      flex-direction: row;
      gap: 24px;

      .question-step {
        color: #75777a;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;

        .current {
          color: #393c3e;
          font-family: Montserrat;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 40px;
        }
      }
    }

    &-content {
      flex: 1 0 0;
      display: flex;
      flex-direction: row;
      height: calc(100% - 56px);

      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        padding-right: 16px;
        height: 100%;

        .title {
          color: #3a444a;
          font-family: Montserrat;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .content {
          margin-top: 24px;

          .title {
            color: #3a444a;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            /* 150% */
          }

          .description {
            color: #3a444a;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;
          }
        }
      }

      .right {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        .selected-option {
          background: #f6faff !important;
          border: 1px solid #85cfff;
        }

        .option {
          width: 100%;
          padding: 1rem;
          border-radius: 4px;
          background: #fff;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
          margin-bottom: 8px;

          color: #5f5e62;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.1px;

          .form-radio {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 24px;
            height: 24px;
            border: 0.8px solid #e0e0e0;
            background-color: #fff;
            border-radius: 16px;
            position: relative;
            outline: none;
            cursor: pointer;
          }

          .form-radio:checked::before {
            content: "";
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #57b4eb;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .form-radio:checked {
            border: 0.8px solid #57b4eb;
          }
        }

        .button-container {
          display: flex;
          flex-direction: row;
          gap: 8px;

          .previous-button {
            width: 98px;
            display: flex;
            height: 40px;
            padding: 7px 8px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            border: 1px solid #c5c6c9;
            background: #fff;

            color: #454749;
            text-align: center;

            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;
          }

          .button {
            width: 98px;
            display: flex;
            height: 40px;
            padding: 7px 8px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background: #57b4eb;

            color: #fff;
            text-align: center;

            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.25px;

            &:disabled {
              background-color: #57dbc8;
            }
          }
        }
      }
    }
  }
}