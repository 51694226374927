.completion {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    color: #3a444a;
    text-align: center;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .description {
    color: #3a444a;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}

.user-name {
  position: absolute;
  left: 58px;
  color: #fff;
  font-family: Montserrat;
  font-size: 57px;
  font-style: normal;
  font-weight: 900;
  line-height: 64px;
  letter-spacing: -0.25px;
  max-width: 460px;
}
