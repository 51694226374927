.navbar {
  display: none;
}
.welcome {
  width: 100%;
  height: calc(100vh - 90px);

  img {
    height: 80px;
  }
  .title {
    color: #393c3e;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 10px;
  }
  .text-content {
    color: #393c3e;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  .button {
    margin-top: 10px;
    width: 100%;
    display: flex;
    padding: 12px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: #57b4eb;

    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}

.welcome-button {
  margin-top: 10px;
  display: flex;
  width: 30%;
  padding: 12px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background: #57b4eb;

  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}
